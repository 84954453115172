.colour-container {
    display: flex;
    flex-wrap: wrap;

    .avatar-colour {
        @apply mx-1 mb-2 rounded-3xl;

        width: 45px;
        height: 45px;

        fa-icon {
            display: block;
            margin-top: 2px;
            margin-left: 2px;
            font-size: 1.3rem;
            color: white;
            // line-height: 4.1;
        }
        fa-icon.dark {
            color: #000;
        }
    }
}


//.onboard,
.modalpage {

    font-family: var(--app-font);
    @apply sm:bg-gray-100 h-full;

    .container,
    ion-slides {
        @apply max-w-screen-lg sm:m-auto sm:shadow-lg sm:bg-white sm:rounded-xl;
    }
    .container-inner,
    ion-slide {
        @apply sm:m-auto;
    }
    .slide-component {
        @apply h-screen w-full sm:h-full;
    }
    .wrapper {
        @apply px-3 sm:px-10 sm:pt-12 sm:pb-8;
    }
    .header {
        @apply text-center text-black mt-10 mb-2 font-bold text-3xl
            sm:mt-0;
            // sm:text-left

        span {
            @apply font-normal text-sm mx-2;
            color: #A2A4Ac;
        }
    }
    .content {
        @apply md:grid md:grid-cols-2;

        .desc {
            @apply py-5 sm:pt-0 sm:text-left;
        }
        .desc-center {
            @apply py-5 sm:pt-0 sm:text-center;
        }
        .pagename {
            @apply text-center pt-10 mb-10 font-bold text-3xl
                sm:text-center sm:mt-0;
        }
    }
    .content-one {
        @apply md:grid md:grid-cols-1;
    }
    .content-category {
        @apply grid md:grid-cols-2 lg:grid-cols-4;

        .option {
            @apply px-5 py-4 text-center rounded-xl border-2 border-solid border-gray-100 mx-2 my-2 lg:my-6
                hover:bg-gray-100 hover:border-gray-100;

            p { @apply mt-8 }
        }
    }
    .img-hide-mobile {
        @apply invisible hidden
            sm:h-72 sm:mt-7 sm:visible sm:block;

        img { @apply m-auto sm:m-auto; }
    }
    .img-show-mobile {
        @apply h-48 visible block w-full py-5
            sm:h-72 sm:mt-7 sm:pb-0;

        img      { @apply m-auto sm:m-auto; }
        img.left { @apply m-auto sm:mx-0;   }
    }
    .buttonbar {
        @apply w-full px-7 text-center my-6 relative
            sm:my-9;

        ion-button {
            @apply text-sm;
        }
    }
    .toolbar {
        @apply w-full px-7 text-center my-6 bottom-0 absolute
            sm:text-right sm:my-9 sm:relative;
    }
    .toolbar-bottom {
        @apply w-full
            px-7
            text-center
            my-6 sm:my-9;
			//absolute bottom-0;
            //sm:text-right
    }

    .label-before-input {
        margin-left: -1rem;
        @apply mt-1;

        .prop-label {
            text-transform: uppercase;
        }
    }

}



.text-error {
    @apply text-white bg-black text-sm py-2 px-3 mb-5
        sm:inline-block sm:pr-4;
}
