/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

// TODO: CHECK IF WE CAN LEAVE IT REMOVED
// /* Basic CSS for apps built with Ionic */
// @import "@ionic/angular/css/normalize.css";
// @import "@ionic/angular/css/structure.css";
// @import "@ionic/angular/css/typography.css";
// @import '@ionic/angular/css/display.css';

/* Tailwind Imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '@taiga-ui/core/styles/taiga-ui-local.scss';


/* 	fixes @tailwind base in base.scss which makes img display as block
	making image in ngx-image-cropper go to the left */
img {
	display: inline-block;
}

/* hide recaptcha badge*/
.grecaptcha-badge {
    visibility: hidden !important;
}

/* only show recaptcha badge on needed pages */
body.recaptcha .grecaptcha-badge {
    visibility: visible;
}


// .safe--area {
// 	padding-top: constant(safe-area-inset-top);
// 	padding-top: env(safe-area-inset-top);
// 	--ion-safe-area-top: 20px;
// 	--ion-safe-area-bottom: 20px;
// }