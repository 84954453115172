@use "partials/onboard.scss";
@use "partials/sliders.scss";
@import "colors.scss";



.w-checkbox {
    color: black;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 30px;
    display: block;
    font-weight: 400;
    width: 100%;

    a {
        text-decoration: underline;
    }
}

.w-errormsg,
.note {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 30px;
    display: block;
    font-weight: 400;
    width: 100%;
    text-align: center;
    color: black;
}

.w-errormsg {
    color: #cf0606;
}

.value-view-mode {
	@apply mt-3 whitespace-pre-wrap font-semibold;
}

.input-view-mode {
	@apply mt-1 whitespace-pre-wrap font-semibold;
}

// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV


qrcode {
    margin: auto;

    div.qrcode {
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
    }
}


// SEARCHBAR

.searchbar {
    margin: 20px 0 0 0;
    --box-shadow: none;
    border-bottom: 1px solid #CCC;
}

.remaining-chars {
    text-align: right;
    padding-top: 10px;
    font-size: 13px;
    color: #999;
}


/// GENERIC

.w-note {
    font-size: 14px;
    color: #666666;
}



/// GO PREMIUM

.w-lock {
    color: #999999;
    sup {
        fa-icon {
            color: #C911C0; //#999
            font-size: 14px !important;
        }
    }
}

sup.premium {
    color: #C911C0;
    fa-icon {
        color: #C911C0; //#999
        font-size: 14px !important;
        margin-left: 5px;
    }
}

button.go-premium {
    color: #FFFFFF;
    background-color: #C911C0;
    padding: 4px 12px;
    font-size: 13px;
    letter-spacing: 0.03rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
    margin-left: 12px;

    &:hover {
        background-color: #920C8C;
    }
}

button.go-premium-content {
    color: #FFFFFF;
    background-color: #C911C0;
    padding: 0 16px;
    font-size: 13px;
    letter-spacing: 0.8px;
    margin: 3px 0;
    height: 36px;
    border-radius: 0.4rem;
    text-transform: uppercase;

    &:hover {
        background-color: #920C8C;
    }
}


// tags for profile types
.profile-tag__elementLink,
.profile-tag__team {
    color: #FFFFFF;
    background-color: #C911C0;
    padding: 3px 7px;
    border-radius: 8px;
    letter-spacing: 0.6px;
    font-size: 9px;
	white-space: nowrap;
}

.profile-tag__member {
    color: #FFFFFF;
    background-color: #0a50cc;
    padding: 3px 7px;
    border-radius: 8px;
    letter-spacing: 0.6px;
    font-size: 9px;
	white-space: nowrap;
}

.profile-tag__brand {
    color: #FFFFFF;
    background-color: #0398ac;
    padding: 3px 7px;
    border-radius: 8px;
    letter-spacing: 0.6px;
    font-size: 9px;
	white-space: nowrap;
}

.profile-tag__disabled {
    color: #FFFFFF;
    background-color: #AAA;
    padding: 3px 6px 5px 7px;
    border-radius: 8px;
    letter-spacing: 0.6px;
    font-size: 9px;
}
.profile-tag__switch-profile {
    color: #FFFFFF;
    background-color: #1A0D3C;
    padding: 3px 7px;
    border-radius: 8px;
    letter-spacing: 0.6px;
    font-size: 9px;
	white-space: nowrap;
}
