@import '../../common/mixins';

.swiper-wrapper {
    @apply sm:-mt-2; //moves all slider content down
}

.swiper-pagination {
    top: 3px !important;
    bottom: unset !important;
    z-index: 1;
    height: 30px;
}

.swipe {
    top: 0;
    z-index: 0;
}

.swiper-pagination-bullet,
.swipe-bullet {
    width: 20px;
    border-radius: 5px;
    height: 4px;
}

.swiper-pagination-bullet-active,
.swipe-active {
    width: 90px; 
}

.swiper-1 { background-color: #96006B; }
.swiper-2 { background-color: #FC0D70; }
.swiper-3 { background-color: #FEA219; }
.swiper-4 { background-color: #19FEB8; }
.swiper-5 { background-color: #1984FE; }
.swiper-6 { background-color: #9CFE77; }

.xslide {
    z-index: 1;

    img {
        height: 250px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    div {
        margin-top: 50px;
    
        p {
            margin: auto;
            padding-top: 20px;
            font-weight: 200;
            line-height: 30px;
        }
    }
}

@include mobile {
    .swiper-pagination {
        top: 37px !important;
        bottom: unset !important;
        width: 280px !important;
        z-index: 1;
        
        left: 50% !important;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}