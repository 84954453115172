// TAILWIND
// sm	640px	@media (min-width: 640px) { ... }
// md	768px	@media (min-width: 768px) { ... }
// lg	1024px	@media (min-width: 1024px) { ... }
// xl	1280px	@media (min-width: 1280px) { ... }
// 2xl	1536px	@media (min-width: 1536px) { ... }


// @mixin mobile-xs {
//     @media (max-width: 400px) {
//         @content;
//     }
// }

// @mixin mobile {
//     @media (max-width: 575px) {
//         @content;
//     }
// }

@mixin mobile { //mobile-plus
    @media (max-width: 639px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 991px) {
        @content;
    }
}

@mixin desktop-lg {
    @media (max-width: 1200px) {
        @content;
    }
}


@mixin mobile-landscape {
    @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        @content;
    }
}

@mixin landscape {
    @media only screen and (orientation: landscape) {
        @content;
    }
}

@mixin portrait {
    @media (orientation: portrait) { //tablet  and (min-width: 767px)
        @content;
    }
}


// @mixin portrait-tablet {
//     @media (orientation: portrait) and (min-width: 436px) { //tablet
//         @content;
//     }
// }

@mixin size($size) {
    width: $size;
    height: $size;
}