@import '/src/assets/common/mixins';





//////////////////////////////////
// IONIC COMPONENTS

ion-header {
    background: white;
    color: black;
}

ion-title {
    font-weight: bold;
    font-size: 1.3rem;
    //height: 1.8rem;
    line-height: 32px;
}


//////////////////////////////////
// PROFILE GLOBAL CSS

.tags-container__desktop .drag-scroll-content {
    text-align: center;
    margin-top: 9px;
    padding-top: 5px;
}

.landscape-mode-force-show {
    .tags-container__desktop {
        margin-top: 7px;
    }

    .tags-container__desktop .drag-scroll-content {
        margin-top: 9px;
    }
}

@include portrait {
    .tags-container__desktop .drag-scroll-content {
        margin-top: 5px;
    }
}

// TODO: DRAG SCROLL

.wowup-panel .drag-scroll-content {
    display: block !important;
}

// .show-in-desktop {
//     @apply  hidden invisible
//             sm:block sm:visible;
// }

// .show-in-mobile {
//     @apply  block visible
//             sm:hidden sm:invisible;
// }

.show-cell-in-desktop {
    @apply  hidden invisible
            sm:table-cell sm:relative sm:visible;
}

.show-in-desktop__inline {
    display: inline-block !important;
    visibility: visible !important;
}

.show-in-desktop {
    display: block !important;
    visibility: visible !important;
}

.show-in-mobile {
    display: none !important;
    visibility: hidden !important;
}

@include mobile {
    app-menu-toggle {
        display: none;
        visibility: hidden;
    }

	.show-in-desktop__inline,
    .show-in-desktop {
        display: none !important;
        visibility: hidden !important;
    }

    .show-in-mobile {
        display: block !important;
        visibility: visible !important;
    }
}





// FORM CLASSES
.item-note {
    font-size: 0.7rem;
    padding-bottom: 10px;
    color: #999;
}

// check: .text-error
.item-error {
    font-size: 0.7rem;
    padding-top: 20px;
    @apply text-red-600;
    // text-align: center !important;
    // color: #cb02ad !important;
    // text-transform: uppercase;
}

.item-error-2 {
    font-size: 0.7rem;
    padding-top: 0;
    text-align: right;
    @apply text-red-600;
}

.toast-message {
    --color: rgb(255, 255, 255);
    --background: rgba(0, 0, 0, 0.9);
	text-align: center;
}


// GOGLE AUTOCOMPLETE
/*Change text in autofill textbox*/
input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
}


////////////////////
// PROFILE
////////////////////


$text: #FFF;

:root {
    --toolbar-height: 52px;

    --primary-color: 	#FFFFFF; //#870411;
    --secondary-color: 	#BD1C2C;
    --border-color: 	#FFFFFF; // BORDER-COLOR // TODO: use this as background colour?
    --text-color: 		#FFFFFF;
	--btn-text-color:	#FFFFFF;

    --tag-color: 		#000000;
    --profile-background: #161616;

    --body-bg-color: 	#FFFFFF;
    --title-bg-color: 	#64646466; //#870411;
}


[class^="wpane"] {
    border-right: 1px solid #CCC;
    height: 100%;
    width: 100%;
}

[class^="wpane"]:last-child {
    border: none;
}



////////////////////
// PROFILE
////////////////////


ion-fab-button[data-desc] {
    position: relative;
}

ion-fab-button[data-desc]::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    right: 55px;
    bottom: 0px;
    background-color: var(--ion-color-primary);
    padding: 10px;
    border-radius: 15px;
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    line-height: 20px;
}



///////////////////////
/// PROFESSIONS
///////////////////////


.tag {
    color: $text;
    opacity: 0.8;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 10px;
    height: 25px;
    margin-top: 0px;
}

// TODO: DELETE
.link {
    color: $text;
    --border-color: $text;
    width: 270px;
    padding: 2px 0;
}

ion-button {
    border-color: #FFF;
    color: #FFF;
}


@media (prefers-color-scheme: light) {
    // h2 {
    //     margin: auto;
    //     color: #FFF;
    // }
}


/// TAIGA UI COMPONENTS

// hide the gradient color choice
tui-color-selector {
    tui-hosted-dropdown.t-select {
        display: none !important;
    }
}
